<template>
  <vs-sidebar
    spacer
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="lead-overview-data-sidebar items-no-padding lead-overview-data-sidebar--mobile"
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center px-6 lead-overview-data-sidebar__header">
      <button class="lead-overview-data-sidebar__button-back" @click.prevent="isSidebarActiveLocal = false">
        <arrow-icon :color="'#275D73'" :direction="'left'" :width="16" :height="26" @click="isSidebarActiveLocal = false" />
      </button>

      <div class="flex flex-1 items-center justify-between">
        <h4 v-if="leadData.name" class="ml-6 pl-2 lead-overview-data-sidebar__title">{{ leadData.name }}</h4>

        <vs-switch color="#275D73" vs-icon-on="check" vs-icon-off="block" @click="$emit('onSaveContacted', leadData)" v-model="leadData.contacted">
          <span slot="on">{{ $t('vue.yes') }}</span>
          <span slot="off">{{ $t('vue.no') }}</span>
        </vs-switch>
      </div>
    </div>

    <vx-card v-if="leadData" class="card-sidebar">
      <div class="mt-3 card-sidebar__item">
        <p class="card-sidebar__label">{{ $t('vue.email') }}</p>
        <div class="card-sidebar__value">
          <a :href="`mailto:${leadData.email}`">{{ leadData.email }}</a>
        </div>
      </div>

      <div class="card-sidebar__item">
        <p class="card-sidebar__label">{{ $t('vue.phone') }}</p>
        <div class="card-sidebar__value">
          <a :href="`tel:${leadData.phone}`">{{ leadData.phone }}</a>
        </div>
      </div>

      <div class="card-sidebar__item">
        <p class="card-sidebar__label">{{ $t('vue.date') }}</p>
        <div class="card-sidebar__value">{{ leadData.date | modifyDate }}</div>
      </div>

      <div class="card-sidebar__item">
        <p class="card-sidebar__label">{{ $t('inputs.message') }}</p>
        <div class="card-sidebar__value" v-html="leadData.message"></div>
      </div>
    </vx-card>
  </vs-sidebar>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
require('dayjs/locale/de')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

import ArrowIcon from '../../components/icons/ArrowIcon'

export default {
  components: {
    ArrowIcon
  },

  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },

    isMobile: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      leadData: null,
    }
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },

      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    }
  },

  created() {
    this.$serverBus.$on('set-lead-overview-sidebar-data', this.setDataListener)
  },

  beforeDestroy() {
    this.$serverBus.$off('set-lead-overview-sidebar-data')
  },

  methods: {
    setDataListener(value) {
      this.leadData = value
    }
  },

  filters: {
    modifyDate(val) {
      return dayjs(val).format('LLL')
    }
  }
}
</script>

<style lang="scss" scoped>
.card-sidebar {
  height: calc(100% - 80px);
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:vertical {
    background-color: rgb(171, 171, 171);
    border-radius: 6px;
  }
}

.lead-overview-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }

  &__button-back {
    background: none;
    border: none;
  }
}

.scroll-area--data-list-add-new {
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>

<style lang="scss">
.lead-overview-data-sidebar {
  .vs-sidebar {
    width: 100% !important;
    height: 100%!important;

    @media only screen and (max-width: 1024px) {
      max-width: 100% !important;
    }
  }

  .vs-switch {
    width: 55px !important;
  }

  .text-switch  {
    .vs-icon {
      display: none !important;
    }
  }

  &__title {
    color: rgb(38, 38, 41);
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
  }

  .card-sidebar {
    box-shadow: none !important;

    &__item {
      margin-top: 24px;
    }

    &__label {
      color: #626262;
      font-size: 13px;
      font-weight: 700;
      line-height: 16px;
    }

    &__value {
      margin-top: 4px;
      color: #626262;
      font-size: 13px;
      line-height: 16px;
    }

    a {
      color: #3034F7;
    }
  }
}
</style>
