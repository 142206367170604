var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "data-list-container",
      class: { "data-list-container--mobile": _vm.isMobile },
      attrs: { id: "data-list-thumb-view" },
    },
    [
      !_vm.isMobile
        ? _c("chats-view-sidebar", {
            attrs: { isSidebarActive: _vm.showChatsSideBar, data: _vm.chats },
            on: { closeSidebar: _vm.toggleChatsDataSidebar },
          })
        : _vm._e(),
      _vm.isMobile
        ? _c("LeadOverviewSidebar", {
            attrs: {
              isSidebarActive: _vm.isOpenedLeadOverviewSidebar,
              isMobile: _vm.isMobile,
            },
            on: {
              onSaveContacted: _vm.saveContacted,
              closeSidebar: _vm.closeLeadOverviewSidebar,
            },
          })
        : _vm._e(),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: _vm.$t("modals.deleteLead"),
            color: "danger",
            "cancel-text": _vm.$t("vue.cancel"),
            "accept-text": _vm.$t("vue.delete"),
            active: _vm.removeLead,
          },
          on: {
            cancel: _vm.onCloseModal,
            accept: _vm.deleteLead,
            close: _vm.onCloseModal,
            "update:active": function ($event) {
              _vm.removeLead = $event
            },
          },
        },
        [
          _vm.selectedLead
            ? _c("div", { staticClass: "con-exemple-prompt" }, [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.selectedLead.name))]),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("modals.deleteLeadQuestion")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "connect-modal",
        {
          staticClass: "visitor-modal",
          attrs: {
            show: _vm.showVisitor,
            width: "100%",
            height: "100%",
            bkcolor: "#F7F7F7",
          },
        },
        [_c("visitor")],
        1
      ),
      _c(
        "div",
        { staticClass: "leadoverview-container" },
        [
          _c(
            "div",
            {
              class:
                !_vm.HAS_ACCESS && !_vm.isMobileApp ? "blur-background" : "",
            },
            [
              _c(
                "vs-table",
                {
                  ref: "table",
                  attrs: {
                    pagination: "",
                    "max-items": _vm.itemsPerPage,
                    search: "",
                    data: _vm.sortedLeads,
                    noDataText: _vm.$t("views.home.noDataAvailable"),
                  },
                  on: { selected: _vm.handleSelected },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var data = ref.data
                        return _vm._l(data, function (tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr, attrs: { data: tr } },
                            [
                              _c(
                                "vs-td",
                                {
                                  staticClass: "lead-overview--column",
                                  attrs: { data: data[indextr].contacted },
                                },
                                [
                                  _c(
                                    "vs-switch",
                                    {
                                      attrs: {
                                        color: "#275D73",
                                        "vs-icon-on": "check",
                                        "vs-icon-off": "block",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.saveContacted(
                                            data[indextr]
                                          )
                                        },
                                      },
                                      model: {
                                        value: data[indextr].contacted,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            data[indextr],
                                            "contacted",
                                            $$v
                                          )
                                        },
                                        expression: "data[indextr].contacted",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { attrs: { slot: "on" }, slot: "on" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.isMobile
                                                ? _vm.$t("vue.yes")
                                                : _vm
                                                    .$t("vue.yes")
                                                    .toUpperCase()
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { attrs: { slot: "off" }, slot: "off" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.isMobile
                                                ? _vm.$t("vue.no")
                                                : _vm.$t("vue.no").toUpperCase()
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "vs-td",
                                {
                                  staticClass:
                                    "lead-overview--column lead-overview--wrap",
                                  attrs: { data: data[indextr].name },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data[indextr].name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              !_vm.isMobile
                                ? [
                                    _c(
                                      "vs-td",
                                      {
                                        staticClass:
                                          "lead-overview--column lead-overview--wrap",
                                        attrs: { data: data[indextr].email },
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "mailto:" +
                                                  data[indextr].email,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(data[indextr].email) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          data[indextr].countryName
                                            ? _c("div", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("inputs.country")
                                                    ) + " "
                                                  ),
                                                ]),
                                                _vm._v(
                                                  _vm._s(
                                                    data[indextr].countryName
                                                  ) + "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          data[indextr].companyName
                                            ? _c("div", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("inputs.company")
                                                    ) + " "
                                                  ),
                                                ]),
                                                _vm._v(
                                                  _vm._s(
                                                    data[indextr].companyName
                                                  ) + "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                        _c("div", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "tel:" + data[indextr].phone,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(data[indextr].phone)
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        staticClass:
                                          "lead-overview--column lead-overview--wrap",
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(data[indextr].widgetName) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        staticClass:
                                          "lead-overview--column lead-overview--wrap",
                                      },
                                      [
                                        data[indextr].created
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      data[indextr]
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _c("div", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.formatTime(data[indextr])
                                              ) +
                                              "\n                "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        staticClass:
                                          "lead-overview--visitor-status",
                                      },
                                      [
                                        _c("VisitorStatus", {
                                          attrs: {
                                            online: true,
                                            isVegaWidgetEnabled:
                                              data[indextr].visitorScore &&
                                              data[indextr].isVegaWidgetEnabled,
                                            score: data[indextr].visitorScore,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("vs-td", [
                                      _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex",
                                            style: {
                                              direction: _vm.$vs.rtl
                                                ? "rtl"
                                                : "ltr",
                                            },
                                          },
                                          [
                                            (data[indextr].message &&
                                              data[indextr].message.trim()
                                                .length > 0) ||
                                            data[indextr].type ===
                                              "lead-form-saved"
                                              ? _c("vs-button", {
                                                  attrs: {
                                                    color: "success",
                                                    type: "border",
                                                    size: "medium",
                                                    "icon-pack": "feather",
                                                    icon: "icon-message-square",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.viewChats(
                                                        data[indextr]
                                                      )
                                                    },
                                                  },
                                                })
                                              : _c("vs-button", {
                                                  attrs: {
                                                    color: "dark",
                                                    disabled: "",
                                                    type: "border",
                                                    size: "medium",
                                                    "icon-pack": "feather",
                                                    icon: "icon-message-square",
                                                  },
                                                }),
                                            _c("vs-button", {
                                              staticClass: "ml-3",
                                              attrs: {
                                                color: "primary",
                                                type: "border",
                                                size: "medium",
                                                "icon-pack": "feather",
                                                icon: "icon-info",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.viewVisitorData(
                                                    data[indextr]
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "vx-tooltip",
                                              {
                                                staticClass: "ml-3",
                                                attrs: {
                                                  text: _vm.$t("vue.delete"),
                                                  position: "top",
                                                },
                                              },
                                              [
                                                _c("vs-button", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.activeUserInfo
                                                        .role !== "admin",
                                                    color: "danger",
                                                    type: "border",
                                                    size: "medium",
                                                    "icon-pack": "feather",
                                                    icon: "icon-trash",
                                                  },
                                                  on: {
                                                    click: function () {
                                                      return _vm.onDeleteClick(
                                                        tr
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        })
                      },
                    },
                  ]),
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative flex flex-wrap-reverse items-center flex-grow justify-start data-list-subscriptions-wrapper",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("div", { staticClass: "lead-overview__headers" }, [
                        _c("div", { staticClass: "lead-overview__header" }, [
                          _c(
                            "div",
                            { staticClass: "mt-1 mb-1 mr-5" },
                            [
                              _c("subscriptions", {
                                attrs: { isMobile: _vm.isMobile },
                              }),
                            ],
                            1
                          ),
                          _vm.activeUserInfo &&
                          ["superadmin", "admin"].includes(
                            _vm.activeUserInfo.role
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "mt-1 mb-1 mr-5",
                                  staticStyle: { width: "200px" },
                                },
                                [
                                  _c("multiselect", {
                                    staticClass:
                                      "lead-overview__header--multiselect",
                                    attrs: {
                                      options: _vm.userGroupList,
                                      multiple: true,
                                      "close-on-select": false,
                                      "clear-on-select": false,
                                      "preserve-search": true,
                                      selectLabel: "",
                                      selectedLabel: "",
                                      deselectLabel: "",
                                      placeholder: _vm.$t(
                                        "vue.selectUserGroup"
                                      ),
                                      label: "name",
                                      "track-by": "name",
                                      "preselect-first": false,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function (ref) {
                                            var values = ref.values
                                            var isOpen = ref.isOpen
                                            return _vm._l(
                                              values,
                                              function (agent, index) {
                                                return _c(
                                                  "vs-chip",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !isOpen,
                                                        expression: "!isOpen",
                                                      },
                                                    ],
                                                    key: index,
                                                    attrs: { color: "primary" },
                                                  },
                                                  [_vm._v(_vm._s(agent.name))]
                                                )
                                              }
                                            )
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3675091459
                                    ),
                                    model: {
                                      value: _vm.groups,
                                      callback: function ($$v) {
                                        _vm.groups = $$v
                                      },
                                      expression: "groups",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "mt-1 mb-1 mr-5" },
                            [
                              !_vm.isMobile
                                ? _c(
                                    "vx-tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t("vue.download"),
                                        position: "top",
                                      },
                                    },
                                    [
                                      _c("vs-button", {
                                        attrs: {
                                          color: "primary",
                                          type: "border",
                                          size: "medium",
                                          "icon-pack": "feather",
                                          icon: "icon-download",
                                        },
                                        on: { click: _vm.exportCSV },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-1 mb-1 mr-5" },
                            [
                              !_vm.isMobile
                                ? _c(
                                    "vs-dropdown",
                                    {
                                      staticClass: "cursor-pointer mr-4",
                                      attrs: { "vs-trigger-click": "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                                          staticStyle: {
                                            "border-radius": "5px",
                                          },
                                        },
                                        [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.currentPage *
                                                  _vm.itemsPerPage -
                                                  (_vm.itemsPerPage - 1)
                                              ) +
                                                " -\n                      " +
                                                _vm._s(
                                                  _vm.sortedLeads.length -
                                                    _vm.currentPage *
                                                      _vm.itemsPerPage >
                                                    0
                                                    ? _vm.currentPage *
                                                        _vm.itemsPerPage
                                                    : _vm.sortedLeads.length
                                                ) +
                                                " of " +
                                                _vm._s(_vm.queriedItems)
                                            ),
                                          ]),
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "ChevronDownIcon",
                                              svgClasses: "h-4 w-4",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vs-dropdown-menu",
                                        [
                                          _c(
                                            "vs-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.itemsPerPage = 5
                                                },
                                              },
                                            },
                                            [_c("span", [_vm._v("5")])]
                                          ),
                                          _c(
                                            "vs-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.itemsPerPage = 10
                                                },
                                              },
                                            },
                                            [_c("span", [_vm._v("10")])]
                                          ),
                                          _c(
                                            "vs-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.itemsPerPage = 15
                                                },
                                              },
                                            },
                                            [_c("span", [_vm._v("15")])]
                                          ),
                                          _c(
                                            "vs-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.itemsPerPage = 20
                                                },
                                              },
                                            },
                                            [_c("span", [_vm._v("20")])]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "lead-overview__header2" }, [
                          _c("div", {
                            staticClass: "flex flex-wrap-reverse items-center",
                          }),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", { attrs: { "sort-key": "contacted" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("vue.contacted")) +
                            "\n          "
                        ),
                      ]),
                      _c("vs-th", { attrs: { "sort-key": "name" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("vue.name")) +
                            "\n          "
                        ),
                      ]),
                      !_vm.isMobile
                        ? [
                            _c("vs-th", { attrs: { "sort-key": "email" } }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("vue.contactDetails")) +
                                  "\n            "
                              ),
                            ]),
                            _c(
                              "vs-th",
                              { attrs: { "sort-key": "widgetName" } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("vue.widgetName")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _c("vs-th", { attrs: { "sort-key": "created" } }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("vue.date")) +
                                  "\n            "
                              ),
                            ]),
                            _c("vs-th", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("vue.status")) +
                                  "\n            "
                              ),
                            ]),
                            _c("vs-th", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("vue.action")) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          ),
          !_vm.HAS_ACCESS && !_vm.isMobileApp
            ? _c("lead-overview-message")
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }