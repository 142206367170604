<template>
  <div
    class="btn-add-new mw-255 rounded-lg cursor-pointer flex items-center justify-left text-lg font-medium text-base text-primary"
    :class="{ 'mr-auto': isMobile }"
  >
    <template v-if="!isMobile">
      <feather-icon icon="BellIcon" svgClasses="h-4 w-4" />
      <label class="ml-2 mr-2" style="font-size: 12px">{{ $t('vue.notifications') }}</label>
    </template>
    <vs-dropdown vs-custom-content vs-trigger-click class="mr-2" :class="{ 'pa-dropdown--mobile': isMobile }">
      <template v-if="isMobile">
        <feather-icon icon="BellIcon" svgClasses="h-4 w-4" />
        <label class="ml-2 mr-2" style="font-size: 12px">{{ $t('vue.notifications') }}</label>
      </template>

      <a class="a-icon" href.prevent :class="{ flex: isMobile }">
        <vs-icon class="" icon="expand_more"></vs-icon>
      </a>
      <vs-dropdown-menu class="notification-type">
        <vs-checkbox @change="updateSubscription" v-model="leadSubscriptions.email">{{ $t('vue.email') }}</vs-checkbox>
      </vs-dropdown-menu>
    </vs-dropdown>

    <popper
      v-if="isMobile"
      trigger="clickToToggle"
      :options="{
        placement: 'top',
        modifiers: { offset: { offset: '0,10px' } }
      }"
    >
      <div class="popper custom-popper">
        {{ $t('info.leadNotifications') }}
      </div>

      <label slot="reference" class="flex">
        <img width="17" height="17" :src="infoImg" style="display: inline-block" />
      </label>
    </popper>

    <vx-tooltip v-else class="flex items-center" style="width: 17px" :text="$t('info.leadNotifications')" position="top">
      <img width="17" height="17" :src="infoImg" style="display: inline-block" />
    </vx-tooltip>
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

import { mapGetters } from 'vuex'
export default {
  components: {
    Popper
  },

  props: {
    isMobile: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      infoImg: require('@/assets/images/elements/info.svg'),
      leadSubscriptions: {
        email: true
      },
      endpoints: []
    }
  },
  created() {
    if (this.activeUserInfo && this.activeUserInfo.leadSubscriptions) {
      this.leadSubscriptions = this.activeUserInfo.leadSubscriptions
    }
    if (this.activeUserInfo && typeof this.activeUserInfo.leadSubscriptions === 'undefined') {
      this.updateSubscription()
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company'
    })
  },

  methods: {
    async updateSubscription() {
      const userRef = this.$db.collection('users').doc(this.activeUserInfo.uid)
      await userRef.set({ leadSubscriptions: this.leadSubscriptions }, { merge: true })
      this.$store.commit('UPDATE_USER_INFO', { leadSubscriptions: { ...this.leadSubscriptions } })
    }
  }
}
</script>

<style lang="scss">
.pa-dropdown--mobile {
  display: flex;
  align-items: center;
  font-family: inherit;
}

.notification-type__checkbox {
  .vs-checkbox {
    min-width: 20px;
    min-height: 20px;
  }
}

.custom-popper {
  padding: 8px 10px;
  max-width: calc(100% - 10px);
  color: rgb(255, 255, 255);
  font-size: 12px !important;
  line-height: 14.4px !important;
  font-weight: 500 !important;
  background: rgb(48, 52, 247);
  border: none !important;
  box-shadow: none !important;
  border-radius: 6px;

  &[x-placement^='top'] .popper__arrow {
    border-width: 5px 5px 1px 5px !important;
    border-color: rgb(48, 52, 247) transparent transparent transparent !important;
  }
}
</style>
