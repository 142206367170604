var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-sidebar",
    {
      staticClass:
        "lead-overview-data-sidebar items-no-padding lead-overview-data-sidebar--mobile",
      attrs: {
        spacer: "",
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "primary",
      },
      model: {
        value: _vm.isSidebarActiveLocal,
        callback: function ($$v) {
          _vm.isSidebarActiveLocal = $$v
        },
        expression: "isSidebarActiveLocal",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "mt-6 flex items-center px-6 lead-overview-data-sidebar__header",
        },
        [
          _c(
            "button",
            {
              staticClass: "lead-overview-data-sidebar__button-back",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.isSidebarActiveLocal = false
                },
              },
            },
            [
              _c("arrow-icon", {
                attrs: {
                  color: "#275D73",
                  direction: "left",
                  width: 16,
                  height: 26,
                },
                on: {
                  click: function ($event) {
                    _vm.isSidebarActiveLocal = false
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex flex-1 items-center justify-between" },
            [
              _vm.leadData.name
                ? _c(
                    "h4",
                    {
                      staticClass:
                        "ml-6 pl-2 lead-overview-data-sidebar__title",
                    },
                    [_vm._v(_vm._s(_vm.leadData.name))]
                  )
                : _vm._e(),
              _c(
                "vs-switch",
                {
                  attrs: {
                    color: "#275D73",
                    "vs-icon-on": "check",
                    "vs-icon-off": "block",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("onSaveContacted", _vm.leadData)
                    },
                  },
                  model: {
                    value: _vm.leadData.contacted,
                    callback: function ($$v) {
                      _vm.$set(_vm.leadData, "contacted", $$v)
                    },
                    expression: "leadData.contacted",
                  },
                },
                [
                  _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                    _vm._v(_vm._s(_vm.$t("vue.yes"))),
                  ]),
                  _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                    _vm._v(_vm._s(_vm.$t("vue.no"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.leadData
        ? _c("vx-card", { staticClass: "card-sidebar" }, [
            _c("div", { staticClass: "mt-3 card-sidebar__item" }, [
              _c("p", { staticClass: "card-sidebar__label" }, [
                _vm._v(_vm._s(_vm.$t("vue.email"))),
              ]),
              _c("div", { staticClass: "card-sidebar__value" }, [
                _c("a", { attrs: { href: "mailto:" + _vm.leadData.email } }, [
                  _vm._v(_vm._s(_vm.leadData.email)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "card-sidebar__item" }, [
              _c("p", { staticClass: "card-sidebar__label" }, [
                _vm._v(_vm._s(_vm.$t("vue.phone"))),
              ]),
              _c("div", { staticClass: "card-sidebar__value" }, [
                _c("a", { attrs: { href: "tel:" + _vm.leadData.phone } }, [
                  _vm._v(_vm._s(_vm.leadData.phone)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "card-sidebar__item" }, [
              _c("p", { staticClass: "card-sidebar__label" }, [
                _vm._v(_vm._s(_vm.$t("vue.date"))),
              ]),
              _c("div", { staticClass: "card-sidebar__value" }, [
                _vm._v(_vm._s(_vm._f("modifyDate")(_vm.leadData.date))),
              ]),
            ]),
            _c("div", { staticClass: "card-sidebar__item" }, [
              _c("p", { staticClass: "card-sidebar__label" }, [
                _vm._v(_vm._s(_vm.$t("inputs.message"))),
              ]),
              _c("div", {
                staticClass: "card-sidebar__value",
                domProps: { innerHTML: _vm._s(_vm.leadData.message) },
              }),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }