var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "btn-add-new mw-255 rounded-lg cursor-pointer flex items-center justify-left text-lg font-medium text-base text-primary",
      class: { "mr-auto": _vm.isMobile },
    },
    [
      !_vm.isMobile
        ? [
            _c("feather-icon", {
              attrs: { icon: "BellIcon", svgClasses: "h-4 w-4" },
            }),
            _c(
              "label",
              {
                staticClass: "ml-2 mr-2",
                staticStyle: { "font-size": "12px" },
              },
              [_vm._v(_vm._s(_vm.$t("vue.notifications")))]
            ),
          ]
        : _vm._e(),
      _c(
        "vs-dropdown",
        {
          staticClass: "mr-2",
          class: { "pa-dropdown--mobile": _vm.isMobile },
          attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
        },
        [
          _vm.isMobile
            ? [
                _c("feather-icon", {
                  attrs: { icon: "BellIcon", svgClasses: "h-4 w-4" },
                }),
                _c(
                  "label",
                  {
                    staticClass: "ml-2 mr-2",
                    staticStyle: { "font-size": "12px" },
                  },
                  [_vm._v(_vm._s(_vm.$t("vue.notifications")))]
                ),
              ]
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "a-icon",
              class: { flex: _vm.isMobile },
              attrs: { "href.prevent": "" },
            },
            [_c("vs-icon", { attrs: { icon: "expand_more" } })],
            1
          ),
          _c(
            "vs-dropdown-menu",
            { staticClass: "notification-type" },
            [
              _c(
                "vs-checkbox",
                {
                  on: { change: _vm.updateSubscription },
                  model: {
                    value: _vm.leadSubscriptions.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.leadSubscriptions, "email", $$v)
                    },
                    expression: "leadSubscriptions.email",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("vue.email")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.isMobile
        ? _c(
            "popper",
            {
              attrs: {
                trigger: "clickToToggle",
                options: {
                  placement: "top",
                  modifiers: { offset: { offset: "0,10px" } },
                },
              },
            },
            [
              _c("div", { staticClass: "popper custom-popper" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("info.leadNotifications")) +
                    "\n    "
                ),
              ]),
              _c(
                "label",
                {
                  staticClass: "flex",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c("img", {
                    staticStyle: { display: "inline-block" },
                    attrs: { width: "17", height: "17", src: _vm.infoImg },
                  }),
                ]
              ),
            ]
          )
        : _c(
            "vx-tooltip",
            {
              staticClass: "flex items-center",
              staticStyle: { width: "17px" },
              attrs: {
                text: _vm.$t("info.leadNotifications"),
                position: "top",
              },
            },
            [
              _c("img", {
                staticStyle: { display: "inline-block" },
                attrs: { width: "17", height: "17", src: _vm.infoImg },
              }),
            ]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }